<template>
<div class="player-box flex flex-row" :class="{'current-player' : gameStatus == 1 && isCurrent}">
  <div class="flex flex-col">
    <div class="flex flex-row">
      <div>
        {{ playerMeta.playerName }}
      </div>
      <div class="ml-2" v-if="turnInfo">
        <span class="font-light">{{ rank }}</span><span class="font-normal">{{ suit }}</span>
      </div>
    </div>
    <div class="font-light">
      Score : {{ totalScore }}
    </div>
    <div class="font-light" v-if="gameStatus === 1 && Object.keys(handInfo).length">
      Hands : {{ handInfo.handsAcquired }} / {{ (handInfo.handsRequired !== null ? handInfo.handsRequired : "?") }}
    </div>
  </div>
  <div>

  </div>
</div>
</template>

<script>
export default {
  props: {
    playerMeta: {
      type: Object
    },
    handInfo: {
      type: Object,
      default: () => ({})
    },
    turnInfo: {
      type: String,
      default: ""
    },
    roundScores: {
      type: Object,
      default: () => ({})
    },
    gameStatus: {
      type: Number,
      default: 1
    },
    isCurrent: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    totalScore: function () {
      let score = 0;
      for (let i = 0; i < Object.keys(this.roundScores).length; i++) {
        score += this.roundScores[Object.keys(this.roundScores)[i]];
      }
      return score;
    },
    suit: function() {
      if(this.turnInfo) {
        return this.turnInfo.slice(-1);
      }
      else {
        return "";
      }
    },
    rank: function() {
      if(this.turnInfo) {
        return this.turnInfo.slice(0,-1);
      }
      else {
        return "";
      }
    },
  }
};
</script>
<style>
.player-box {
  background-color: #C4C4C4;
  border-radius: 7px;
  padding: 0.5rem;
}
.current-player {
  background-color:hsl(0, 0%, 65%);;
}
</style>