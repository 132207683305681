<template>
  <div class="max-w-2xl p-4 main-card flex flex-col items-center" :class="{'main-card-modified' : toggleOverflow}">
    <div class="flex flex-row justify-between w-full px-6">
      <div>
        <h1 class="font-light text-2xl">{{roomId}}</h1>
      </div>
      <div class="font-light text-sm mt-2">{{ new Date(roomPlayerInfo.createdAt).toLocaleTimeString() }}</div>
    </div>
    <div class="divider">
    </div>
    <div v-if="gameInfo.currNumOfCards && gameInfo.currNumOfCards < gameInfo.maxNumOfCards + 1" class="flex flex-row">
      <div class="mr-4">
        Round : <span class="font-medium">{{ gameInfo.currNumOfCards }} / {{ gameInfo.maxNumOfCards }}</span>
      </div>
      <div class="mr-4">
        Hand : <span class="font-medium">{{ gameInfo.currNumOfCards - gameInfo.remainingCardsInCurrentRound }}</span>
      </div>
      <div>
        Trump
      </div>
      <div class="w-5 ml-2">
        <img :src="trumpSource" alt="Trump Suit" />
      </div>
    </div>
    <div v-else-if="gameInfo.currNumOfCards">
      Game Over
    </div>
    <div v-else>
      Game not started yet
    </div>

    <div class="flex flex-row flex-wrap player-outer" :class="{'overflow-auto' : !toggleOverflow}">
      <div v-for="player in roomPlayerInfo.players" :key="player.id" class="">
        <Player :player-meta="player" class="mx-2 my-2 player-box"
          :turn-info="gameInfo.currentTurnInfo ? gameInfo.currentTurnInfo[player.id] : undefined"
          :hand-info="gameInfo.playerHands ? gameInfo.playerHands[player.id] : undefined"
          :round-scores="gameInfo.currentScores ? gameInfo.currentScores[player.id] : undefined"
          :game-status="gameStatus"
          :is-current="gameInfo.players ? gameInfo.players[gameInfo.currentPlayerIndex] === player.id : false"
         />
      </div>
    </div>
    <div class="flex flex-row mt-auto w-full">
      <span v-if="gameInfo.currNumOfCards && gameInfo.currNumOfCards >= gameInfo.maxNumOfCards + 1" class="mt-2 ml-2 text-sm">Rounds played {{ gameInfo.maxNumOfCards }}</span>
      <transition name = "fade">
        <div class="ml-auto text-2xl cursor-pointer w-4" @click="toggleOverflow = !toggleOverflow">
          {{ toggleOverflow ? '-' : '+' }}
        </div>
      </transition>
    </div>

    <!-- {{ roomPlayerInfo }} -->
    
    
  </div>
</template>

<script>
import Player from './Player.vue';
export default {
  components: {
    Player
  },
  data: function() {
    return {
      toggleOverflow: false,
    }
  },
  props: {
    gameInfo: {
      type: Object,
      required: true,
    },
    roomPlayerInfo: {
      type: Object,
      required: true,
    },
    roomId: {
      type: String,
      required: true
    },
  },
  computed: {
    trumpSource: function() {
      if(this.gameInfo.trumpSuit) {
        return require('../assets/' + this.gameInfo.trumpSuit.toLowerCase() + '.png');
      }
      else {
        return '';
      }
    },
    gameStatus: function() {
      if(this.gameInfo.currNumOfCards && this.gameInfo.currNumOfCards < this.gameInfo.maxNumOfCards + 1) {
        return 1;
      } else if(this.gameInfo.currNumOfCards) {
        return 2;
      } else {
        return 0;
      }
    }
  }
};
</script>

<style>
.main-card {
  background-color: #D9D9D9 !important;
  border-radius: 16px;
  width: 387px;
  height: 279px;
}
.main-card-modified {
  height: auto !important;
}
@media only screen and (max-width: 600px) {
  .main-card {
      background-color: #D9D9D9 !important;
      border-radius: 16px;
      width: 330px;
      height: 279px;
  }
}
.divider {
  height: 1px;
  background-color: #000;
  width: 85%;
  margin-bottom: 12px;
}
.player-outer {
  align-self: start;
  justify-self: start;
}
* {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: #C4C4C4;
  border-radius: 43px;
}
*::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  /* border: 3px solid orange; */
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>