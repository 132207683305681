import Vue from 'vue';
import App from './App.vue';
import './assets/css/tailwind.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import ToggleButton from 'vue-js-toggle-button'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(ToggleButton)
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
}).$mount('#app')
