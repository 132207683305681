<template>
  <div id="app">
    <Header
      class="header-class"
      :isProd="isProd"
      :isDataArchive="isDataArchive"
      :apiKey="apiKey"
      :isLoading.sync="isLoading"
      :isError="isError"
      @serverChange="onServerChange"
      @dataSourceChange="onDataSourceChange"
      @refreshData="refreshPressed"
      @apiKeyChange="onApiKeyChange"
    />
    <div v-if="!isDataArchive" class="flex flex-col items-center mt-20">
      <div class="flex flex-row mx-4 mt-4">
        <div class="font-light text-2xl">
          Total number of games :
          <span class="font-medium">{{ Object.keys(gameInfo).length }}</span>
        </div>
        <div class="font-light text-2xl cond-left">
          Number of players :
          <span class="font-medium">{{ numberOfPlayers }}</span>
        </div>
      </div>
      <div class="flex flex-row flex-wrap m-4">
        <div class="font-light text-2xl">
          Active games :
          <span class="font-medium">{{ numberOfActiveGames }}</span>
        </div>
        <div class="font-light text-2xl cond-left">
          Completed games :
          <span class="font-medium">{{ numberOfCompletedGames }}</span>
        </div>
        <div class="font-light text-2xl cond-left">
          Games not started :
          <span class="font-medium">{{ numberOfGamesNotStarted }}</span>
        </div>
      </div>
      <div class="font-light text-2xl px-5">
        Latest room created at: {{ latestGameCreatedAt }}
      </div>
    </div>
    <div class="main-divider"></div>
    <div v-if="!isDataArchive" class="flex flex-row flex-wrap justify-center p-6">
      <div
        v-for="item in Object.keys(gameInfo).reverse()"
        :key="item"
        class="m-3"
      >
      <div v-if="roomPlayerInfo[item]">
        <room-info
          :gameInfo="gameInfo[item]"
          :roomPlayerInfo="roomPlayerInfo[item]"
          :roomId="item"
        />
      </div>
      </div>
    </div>
    <div v-else class="flex flex-wrap mt-20">
      <analytics-card class="mb-2" style="width: 40rem" v-for="param in Object.keys(analyticsData)" :key="param" :data="analyticsData[param].data" :isLoading="analyticsData[param].isLoading" :title="convertCase(param)" />
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Header from "./components/Header.vue";
import RoomInfo from "./components/RoomInfo.vue";
import axios from "axios";
import AnalyticsCard from './components/AnalyticsCard.vue';
export default {
  name: "App",
  components: {
    Header,
    RoomInfo,
    AnalyticsCard,
  },
  data: function () {
    return {
      gameInfo: {},
      roomPlayerInfo: {},
      isProd: true,
      isDataArchive: false,
      apiKey: "",
      isLoading: false,
      isError: false,
      prodServerUrl: "https://judgementcard.games/",
      devServerUrl: "https://cryptic-retreat-37771.herokuapp.com/",
      analyticsData: {
        totalGames: {
          isLoading: true,
          data: undefined,
        },
        completedGames: {
          isLoading: true,
          data: undefined,
        },
        totalPlayers: {
          isLoading: true,
          data: undefined,
        },
        gamePlayers: {
          isLoading: true,
          data: undefined,
        }
      }
    };
  },
  beforeMount() {
    this.apiKey = this.$cookies.get("judgement_api_key");
    this.isProd = this.$cookies.get("judgement_server") === "true";
    this.isDataArchive = this.$cookies.get("judgement_data_source") === "true";
  },
  async mounted() {
    this.isDataArchive ? this.refreshArchiveData() : this.refreshGameData();
    document.addEventListener("keyup", this.onRefreshPressed);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.onRefreshPressed);
  },
  methods: {
    onServerChange: function (val) {
      this.isProd = val;
      this.$cookies.set("judgement_server", this.isProd, "1y");
      this.refreshGameData();
    },
    onDataSourceChange: function (val) {
      this.isDataArchive = val;
      this.$cookies.set("judgement_data_source", this.isDataArchive, "1y");
      val ? this.refreshArchiveData() : this.refreshGameData();
    },
    onApiKeyChange: function (val) {
      this.apiKey = val;
      this.$cookies.set("judgement_api_key", this.apiKey, "1y");
      this.refreshGameData();
    },
    onRefreshPressed(event) {
      if (event.keyCode === 82) {
        this.refreshGameData();
      }
    },
    convertCase(text) {
      const result = text.replace( /([A-Z])/g, " $1" );
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    refreshPressed() {
      if(this.isDataArchive) {
        this.refreshArchiveData();
      } else {
        this.refreshGameData();
      }
    },
    refreshGameData: async function () {
      try {
        this.isLoading = true;
        let reqUrl = this.isProd ? this.prodServerUrl : this.devServerUrl;
        reqUrl += "api/showCurrentLog";
        let { data } = await axios.get(reqUrl, {
          params: { key: this.apiKey },
        });
        this.isLoading = false;
        this.isError = false;
        this.gameInfo = data.gameInfo;
        this.roomPlayerInfo = data.roomPlayerInfo;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.isError = true;
        this.gameInfo = {};
        this.roomPlayerInfo = {};
        console.log("Can't fetch game info");
      }
    },
    refreshArchiveData: async function () {
      this.isLoading = true;
      try {
        for(const param in this.analyticsData) {
          this.analyticsData[param]['isLoading'] = true;
        }

        console.log(process.env);
        axios.get(`${process.env.VUE_APP_ARCHIVE_BASE_URL}/analytics/number-of-players?apiKey=abc134@@`).then(value => {
          const {data} = value;

          for(const k in data) {
            this.analyticsData[k]['isLoading'] = false;
            this.analyticsData[k]['data'] = data[k];
          }

        });

        axios.get(`${process.env.VUE_APP_ARCHIVE_BASE_URL}/analytics/number-of-games?apiKey=${this.apiKey.slice(0, -2)}@@`).then(value => {
          const {data} = value;

          for(const k in data) {
            this.analyticsData[k]['isLoading'] = false;
            this.analyticsData[k]['data'] = data[k];
          }
          this.isLoading = false;
        });

      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    }
  },
  computed: {
    numberOfPlayers: function () {
      let playerSet = new Set();
      try {
        for (let i = 0; i < Object.keys(this.gameInfo).length; i++) {
          if (
            this.gameInfo[Object.keys(this.gameInfo)[i]] &&
            this.gameInfo[Object.keys(this.gameInfo)[i]].players
          ) {
            if(!this.roomPlayerInfo[Object.keys(this.gameInfo)[i]]) {
              continue;
            }
            let roomPlayerIds = this.roomPlayerInfo[
              Object.keys(this.gameInfo)[i]
            ].players.map((pl) => pl.id);
            roomPlayerIds.forEach((element) => {
              playerSet.add(element);
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
      return playerSet.size;
    },
    latestGameCreatedAt: function () {
      let createdTimes = Object.keys(this.roomPlayerInfo).map(
        (roomId) => new Date(this.roomPlayerInfo[roomId].createdAt)
      );
      createdTimes.sort((a, b) => b - a);
      if (createdTimes.length) {
        return createdTimes[0].toLocaleString();
      }
      return "";
    },
    numberOfActiveGames: function () {
      let counter = 0;
      for (const game in this.gameInfo) {
        if (
          this.gameInfo[game].currNumOfCards &&
          this.gameInfo[game].currNumOfCards < 9
        ) {
          counter += 1;
        }
      }
      return counter;
    },
    numberOfCompletedGames: function () {
      let counter = 0;
      for (const game in this.gameInfo) {
        if (
          this.gameInfo[game].currNumOfCards &&
          this.gameInfo[game].currNumOfCards >= (this.gameInfo[game].maxNumOfCards)
        ) {
          counter += 1;
        }
      }
      return counter;
    },
    numberOfGamesNotStarted: function () {
      let counter = 0;
      for (const game in this.gameInfo) {
        if (!this.gameInfo[game].currNumOfCards) {
          counter += 1;
        }
      }
      return counter;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Roboto, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-divider {
  height: 1px;
  background-color: #000;
  /* width: 85%; */
  margin: 1.5rem 0;
}
.header-class {
  position: fixed;
  top: 0;
  width: 100%;
}
.cond-left {
  margin-left: 2rem;
}
@media only screen and (max-width: 600px) {
  .cond-left {
    margin-left: 0;
  }
}

</style>
