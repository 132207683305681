<template>
  <div class="main-div" style="background: #f3f3f3">
    <div class="text-center text-2xl font-normal">Judgement game logs</div>
    <div
      class="w-8 cursor-pointer"
      style="margin-left: auto"
      @click="onRefreshClick"
    >
      <img
        src="../assets/refresh.png"
        alt=""
        :class="{ refreshSpin: isLoading }"
      />
    </div>
    <div v-if="isError" class="text-red-500 font-medium text-3xl">
      !
    </div>
    <div class="w-8 ml-4">
      <dropdown-menu
        v-model="show"
        :right="false"
        :bottom="true"
        :hover="hover"
        :interactive="interactive"
      >
        <span class="cursor-pointer"
          ><img src="../assets/settings.png" alt="" id="settings-icon"
        /></span>
        <div slot="dropdown" class="flex flex-col pt-1 pl-2">
          <div class="flex flex-row ml-8 mt-6">
            <div class="font-light">API KEY:</div>
            <div class="w-2">
              <form action="" @submit.prevent="">
                <input
                  type="password"
                  name=""
                  ref="apiKeyInput"
                  id="password-input"
                  size="8"
                  @input.prevent="onApiKeyChange"
                />
              </form>
            </div>
          </div>
          <div class="flex flex-row ml-8 mt-6">
            <div
              :class="{ 'font-medium': !isProd, 'font-normal': isProd }"
              class="w-8"
            >
              DEV
            </div>
            <div class="ml-3 mr-3">
              <toggle-button
                ref="serverToggle"
                :height="25"
                :width="50"
                :switch-color="{ checked: '#737373', unchecked: '#737373' }"
                @change="onServerChangeHandler"
                :value="isProd"
                :color="{ checked: '#C4C4C4', unchecked: '#C4C4C4' }"
              />
            </div>
            <div :class="{ 'font-medium': isProd, 'font-normal': !isProd }">
              PROD
            </div>
          </div>
          <div class="flex flex-row ml-8 mt-6">
            <div
              :class="{
                'font-medium': !isDataArchive,
                'font-normal': isDataArchive,
              }"
              class="w-8"
            >
              LIVE
            </div>
            <div class="ml-3 mr-3">
              <toggle-button
                :height="25"
                :width="50"
                :value="isDataArchive"
                :switch-color="{ checked: '#737373', unchecked: '#737373' }"
                :color="{ checked: '#C4C4C4', unchecked: '#C4C4C4' }"
                @change="onDataSourceChangeHandler"
              />
            </div>
            <div
              :class="{
                'font-medium': isDataArchive,
                'font-normal': !isDataArchive,
              }"
            >
              ARCHIVE
            </div>
          </div>
        </div>
      </dropdown-menu>
    </div>
  </div>
</template>

<script>
import DropdownMenu from "@innologica/vue-dropdown-menu";
export default {
  components: {
    DropdownMenu,
  },
  props: {
    isProd: {
      type: Boolean,
      required: true,
    },
    isDataArchive: {
      type: Boolean,
      required: true,
    },
    apiKey: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      show: false,
      hover: false,
      interactive: false,
      localApiKey: "",
      localIsProd: false,
      localIsDataArchive: false,
    };
  },
  mounted: function () {
    this.$refs.apiKeyInput.value = this.apiKey;
    this.localIsProd = this.isProd;
    this.localIsDataArchive = this.isDataArchive;
  },
  methods: {
    onApiKeyChange({ target }) {
      this.$emit("apiKeyChange", target.value);
    },
    onServerChangeHandler(value) {
      this.$emit("serverChange", value.value);
    },
    onDataSourceChangeHandler(value) {
      this.$emit("dataSourceChange", value.value);
    },
    onRefreshClick() {
      this.$emit("refreshData");
    },
  },
};
</script>

<style>
.dropdown-menu {
  background: #e5e5e5 !important;
  margin-left: -15rem !important;
  border-radius: 11px !important;
  border: 0 !important;
  width: 16rem;
  height: 12rem;
}
.show {
  background: #e5e5e5;
  margin-left: -15rem !important;
  border-radius: 11px;
  border: 0;
  width: 16rem;
  height: 12rem;
}
.main-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 5rem;
  padding: 0 2rem;
}
#password-input {
  border: 0;
  background: #e5e5e5;
  font-weight: 300;
  margin-left: 1rem;
}
#password-input:focus {
  border: 0;
}
.refreshSpin {
  animation: spin 1s infinite;
  animation-direction: reverse;
  animation-timing-function: ease-in;
}
@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-180deg);
  }
}
</style>