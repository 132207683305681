<template>
  <div
    class="shadow-xl h-40 flex flex-row justify-center items-center"
    :class="isLoading ? 'w-40' : ''"
  >
    <div v-if="!isLoading" class="flex flex-col">
      <div class="font-bold text-4xl">
        {{ title }}
      </div>
      <div class="font-light text-5xl">
        {{ data }}
      </div>
    </div>
    <div v-else class="w-20 text-center">
      <pulse-loader :color="'#000000'" :size="'1rem'" />
    </div>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  components: {
    PulseLoader,
  },
  props: {
    title: {
      type: String,
    },
    data: {
      type: Number,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>